/* eslint-disable @typescript-eslint/no-unused-vars */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { useTranslation } from "react-i18next";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "./PickupTable.scss";
import Pagination from "../../../../components/Pagination/Pagination";

export interface propFilter {
  pickups: any;
  loading: any;
  totalRows: any;
  pageCount: any;
  setPageCount: any;
  rowsPerPage: any;
  setRowsPerPage: any;
};

const PickupTable: React.FC<propFilter> = ({
  pickups,
  loading,
  totalRows,
  pageCount,
  setPageCount,
  rowsPerPage,
  setRowsPerPage,
}) => {

  const columns = [
    { dataField: "locationId", text: "Nome", sort: true },
    { dataField: "status", text: "Status", sort: true , formatter: addStatus },
    { dataField: ["de", "ate"], text: "Itinerário", sort: true, formatter: addRoute  },
    { dataField: "compFantasia", text: "Fornecedor", sort: true  },
    { dataField: "date", text: "Atualização", sort: true  },
    { dataField: "total", text: "Total", sort: true  },
    { dataField: "id", text: "Ações", formatter: addActionButton },
  ];

  const { t } = useTranslation();

  const [editClick, setEditClick] = useState<any>(false);
  const [detailsModal, setDetailsModal] = useState<any>(false);
  const [currentPickups, setCurrentPickups] = useState<any>();

  /* loading table */

  function loadingFunc() {
    return (
      <>
        <div className="animated-background row-loading"></div>
      </>
    );
  }

  const loadingColumns = [
    { dataField: "pickups", text: "Carregando pickups", formatter: loadingFunc },
  ];

  const loadingPickups = [
    { pickups: "" },
    { pickups: "" },
    { pickups: "" },
    { pickups: "" },
    { pickups: "" },
    { pickups: "" },
    { pickups: "" }
  ];
  /* [END] loading table */

  /* In case of empty table*/
  const notFoundColumns = [{ dataField: "pickups", text: "pickups" }];

  const notFoundPickups = [{ vendas: "Nenhuma pickup encontrada" }];
  /* [END] In case of empty table*/

  // formatter de status
  function addStatus(cell: any, row: any) {    
    return (
      <div
        className={
          cell === "Em análise"
            ? "bg-analise mx-auto"
            : cell === "Ativo"
              ? "bg-ativo mx-auto"
              : cell === "Inativo"
                ? "bg-inativo mx-auto"
                : ""
        }
      >
        {cell}
      </div>
    );
  }

  function addRoute(cell: any, row: any) {
    console.log(row)
    return (
      <OverlayTrigger
        placement="top-start"
        overlay={
          <Tooltip id="tooltip">
            <div>De: {row.de}</div>
            <div>Até: {row.ate}</div>
          </Tooltip>
        }>
        <div className="d-flex flex-column" style={{ fontSize: "14px" }}>
          <div className='text-short'>
            <span className="text-nowrap-custom">
              De: {row.de}
            </span>
          </div>
          <div className='text-short'>
            <span className="text-nowrap-custom">
              Até: {row.ate}
            </span>
          </div>
        </div>
      </OverlayTrigger>
    );
  }

  const rowClasses = (row: any, rowIndex: any) => {
    if (row.status === "Em Análise") {
      return 'border-analise';
    } else if (row.status === "Ativo") {
      return 'border-ativo';
    } else {
      return 'border-inativo';
    }
  };

  //botao de ação
  function addActionButton(cell: any, row: any) {
    return (
      <>
        <div className="d-flex justify-content-center">
          <Dropdown drop="start">
            <Dropdown.Toggle variant="light ">
              <FontAwesomeIcon size="lg" icon={["fal", "ellipsis-h"]} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {/* <Dropdown.Item eventKey="1" disabled={true}>
                <FontAwesomeIcon
                  icon={["fal", "comment-alt-dollar"]}
                  className="mr-2"
                />{" "}
                {t("suppliersTable.account")}
              </Dropdown.Item> */}

              <Dropdown.Item
                eventKey="2"
                href={"/pickup/edit-pickup?id=" + cell}
              >
                <FontAwesomeIcon 
                icon={["fal", "edit"]} 
                className="mr-2" />{" "}
                {t("reservations.table.dropdown.edit")}{" "}
              </Dropdown.Item>

              {/* <Dropdown.Item eventKey="3" disabled={true}>
                <FontAwesomeIcon icon={["fal", "circle"]} className="mr-2" />{" "}
                {t("suppliersTable.status")}
              </Dropdown.Item> */}
              {/* <Dropdown.Item eventKey="5" disabled={true}>
                <FontAwesomeIcon
                  icon={["fal", "file-contract"]}
                  className="mr-2"
                />{" "}
                {t("suppliersTable.contract")}
              </Dropdown.Item> */}
              {/* <Dropdown.Item
                eventKey="4"
                disabled={true}
                className="text-danger remove"
              >
                <FontAwesomeIcon icon={["fal", "trash-alt"]} className="mr-2" />{" "}
                {t("suppliersTable.remove")}
              </Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </>
    );
  };

  if (pickups !== null) {

    return (
      <>
        <div className="table-default pickup-table text-center">
          <div className="table-container">
            <div>
              <BootstrapTable
                bootstrap4
                keyField="id"
                data={pickups}
                columns={columns}
                rowClasses={rowClasses}
              />

              {/* <Pagination
                totalRows={totalRows}
                pageCount={pageCount}
                setPageCount={setPageCount}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                selectNumberRows="yes"
              /> */}
            </div>
          </div>
        </div>
      </>
    );
  } else if (pickups === null && loading === true) {
    return (
      <>
        <div className="table-default loading text-center">
          <div className="table-container">
            <div>
              <BootstrapTable
                bootstrap4
                keyField="id"
                data={loadingPickups}
                hover={true}
                columns={loadingColumns}
                striped={true}
              />
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="table-default pickup-table loading not-found">
          <div className="table-container">
            <div>
              <BootstrapTable
                bootstrap4
                keyField="id"
                data={notFoundPickups}
                hover={true}
                columns={notFoundColumns}
                striped={true}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default PickupTable;