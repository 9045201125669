import CryptoJS from 'crypto-js';
import { useEffect, useState } from 'react';
import api from '../../services/api';



const ShareButton = ({ details, enviroment }: any) => {
    const tourAuth: any = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');
    const supIdentity: any = tourAuth.user?.perfil?.supplierIdentity || null

    const [info, setInfo] = useState<any>(null);

    useEffect(() => {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };
        async function getProductCode(prodCode: string) {
            try {
                const { data } = await api.get(`/Products/GetProductAllChannel?productCode=${prodCode}`, config);
                if (data.status !== 400) {
                    setInfo(data.data.data)
                }
            } catch (error: any) {
            }
        }
        getProductCode(details.productCode);
    }, []);

    const shareTour = () => {
        const TourDetailRender = async (productCode: any) => {
            try {
                const data = await api.post(
                    `/Products/FetchSingle${details.typeProduct.charAt(0)}${details.typeProduct.slice(1).toLowerCase()}Async`,
                    {
                        "productCode": productCode,
                        "lang": "BR",
                        "idCanal": localStorage.getItem('idCanal') || 1
                    }
                );
                if (data.status !== 400) {                    
                    const baseUrl = `https://destinow.com.br/${details.typeProduct.toLowerCase()}/${data.data.data.slugBR}`;

                    const secretKey: any = process.env.REACT_APP_SECRET_KEY;
                    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(supIdentity), secretKey).toString();
                    const queryParams = new URLSearchParams({ afiliado: ciphertext }).toString();
                    const fullUrl = `${baseUrl}?${queryParams}`;

                    navigator.clipboard.writeText(fullUrl)
                        .then(() => {
                            alert('URL copiada para a área de transferência!');
                        })
                        .catch(err => {
                            console.error('Erro ao copiar a URL: ', err);
                        });
                }else{
                    alert('Erro ao copiar a URL!');
                }
            } catch (error) { }
        }
        TourDetailRender(details.productCode)
    };

    if(info !== null){
        if (supIdentity && enviroment === "Destinow" && info?.includes(2) ) {
        return (
            <button className="btn" type="button" onClick={shareTour}>
                Compartilhar
            </button>
        );
    }
}

    return null;
}

export default ShareButton;