//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage } from "@hookform/error-message";
import React, { useEffect, useState } from "react";
import { Accordion, Card, Col, Container, Form, Row } from "react-bootstrap";
import { Controller } from "react-hook-form";
import GetSuppliersFilter from '../../../../components/C2Points/GetSuppliersFilter';
import GetResourceVehicleTypeFilter from "../../../../components/C2Points/GetResourceVehicleTypeFilter";
import GetResourcesTypeIsActiveFilter from "../../../../components/C2Points/GetResourcesTypeIsActiveFilter";
import GetSupplierUsers from "../../../../components/C2Points/GetSupplierUsers";
//import ModalConfirm from "../../../../components/Modal/ModalConfirm/ModalConfirm";

export interface propEdit {
  watch: any;
  errors: any;
  control: any;
  isEdit?: any;
  infoEdit?: any;
}

const ResourceCard: React.FC<propEdit> = ({
  watch,
  errors,
  control,
  isEdit = false,
  infoEdit,
}) => {
  const [, setFilterVehicleType] = useState<any>(0);
  const [, setFilterType] = useState<any>(0);
  /* const [responseText, setResponseText] = useState<any>("Preencha os campos!");
  const [modalResponse, setModalResponse] = useState<any>();
  const [log, setLog] = useState<any>(); */
    
  const resourceTypeAtual = isEdit === true ? {
    value: infoEdit.resourceType.toString(),
    label: infoEdit.resourceTypeDesc,
    vehicle: infoEdit.vehicle,
    people: infoEdit.people,
    guide: infoEdit.guide,
    ticket: infoEdit.ticket,
    restaurant: infoEdit.restaurant
  } : null;

  const supplierAtual = isEdit === true ? {
    supplierId: infoEdit.supplierId,
    compName: infoEdit.supplierDesc
  } : null;

  var watchIsVehicle = isEdit === true ? resourceTypeAtual : watch(`resourceType`, false);
  var watchSupplier = isEdit === true ? supplierAtual : watch(`supplier`, false);

  useEffect(() => {}, [watchIsVehicle, watchSupplier]);
  
  return (
    <>
      <Card className="bg-white mt-4">
        <Accordion>
          <Card.Body>
            <Container className="p-0 mt-3" fluid>
              <>
                <Row>
                  <Form.Group as={Col} md="4" controlId="" className="mb-3">
                    <Form.Label>Tipo de Resource:</Form.Label>
                    <Controller
                      control={control}
                      name="resourceType"
                      defaultValue={
                        isEdit === true
                          ? resourceTypeAtual
                          : ""
                      }
                      rules={{
                        required: {
                          value: true,
                          message: "Por favor, informe o nome",
                        },
                      }}
                      render={({ field }: any) => (
                        <div className="input-select-custom">
                          {isEdit === true ?
                            <span>{field.value ? field.value.label : ''}</span>
                          :
                          <GetResourcesTypeIsActiveFilter
                            propsField={field}
                            propsLabel={"tipo"}
                            propsErrors={errors}
                            setFilterType={setFilterType}
                          />
                          }
                        </div>
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="resourceType"
                      render={({ message }) => (
                        <small style={{ color: "red" }}>{message}</small>
                      )}
                    />
                  </Form.Group>

                  <Form.Group as={Col} md="4" controlId="" className="mb-3">
                    <Form.Label>Fornecedor:</Form.Label>
                    <Controller
                      control={control}
                      name="supplier"
                      defaultValue={
                        isEdit === true
                          ? {
                              supplierId: infoEdit.supplierId,
                              compName: infoEdit.supplierDesc,
                            }
                          : ""
                      }
                      rules={{
                        required: {
                          value: true,
                          message: "Por favor, informe o fornecedor",
                        },
                      }}
                      render={({ field }: any) => (
                        <div className="input-select-custom">
                          {/* <GetSupplierGroupId
                            propsField={field}
                            propsLabel={"supplier"}
                            propsErrors={errors}
                            //setFilterSupplier={setFilterSupplier}
                          /> */}
                          <GetSuppliersFilter
                              propsField={field}
                              propsErrors={errors}
                              propsLabel="Fornecedor"
                          />
                        </div>
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="supplier"
                      render={({ message }) => (
                        <small style={{ color: "red" }}>{message}</small>
                      )}
                    />
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="4"
                    controlId=""
                    className={"mb-3 " +
                      (watchIsVehicle?.people === 1 ? "d-block" : "d-none")
                    }
                  >
                    <Form.Label>Usuario:</Form.Label>
                    <Controller
                      control={control}
                      name="employeeUser"
                      defaultValue={
                        isEdit === true
                          ? {
                              uid: infoEdit.employeeUserUid,
                              nome: infoEdit.employeeUserName,
                            }
                          : ""
                      }
                      // rules={{
                      //   required: {
                      //     value: true,
                      //     message: "Por favor, informe o usuario",
                      //   },
                      // }}
                      render={({ field }: any) => (
                        <div className="input-select-custom">
                          <GetSupplierUsers
                              propsField={field}
                              // propsErrors={errors}
                              propsLabel="Usuario"
                              supplierId={watchSupplier?.supplierId}
                          />
                        </div>
                      )}
                    />
                    {/* <ErrorMessage
                      errors={errors}
                      name="employeeUser"
                      render={({ message }) => (
                        <small style={{ color: "red" }}>{message}</small>
                      )}
                    /> */}
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="4"
                    controlId=""
                    className={"mb-3 " +
                      (watchIsVehicle?.vehicle === 1 ? "d-block" : "d-none")
                    }
                  >
                    <Form.Label>Tipo de Veículo:</Form.Label>
                    <Controller
                      control={control}
                      name="vehicle"
                      defaultValue={
                        isEdit === true
                          ? {
                              value: infoEdit.vehicleType,
                              label: infoEdit.vehicleTypeDesc,
                            }
                          : ""
                      }
                      rules={{
                        required: {
                          value: watchIsVehicle?.vehicle === 1 ? true : false,
                          message: "Por favor, informe o tipo de veículo",
                        },
                      }}
                      render={({ field }: any) => (
                        <div className="input-select-custom">
                          <GetResourceVehicleTypeFilter
                            propsField={field}
                            propsLabel={"tipo"}
                            propsErrors={errors}
                            setFilterVehicleType={setFilterVehicleType}
                          />
                        </div>
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="vehicle"
                      render={({ message }) => (
                        <small style={{ color: "red" }}>{message}</small>
                      )}
                    />
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="4"
                    controlId=""
                    className={
                      "mb-3 " +
                      (watchIsVehicle?.vehicle === 1 ? "d-block" : "d-none")
                    }
                  >
                    <Form.Label>Assentos:</Form.Label>
                    <Controller
                      control={control}
                      name="seats"
                      defaultValue={isEdit === true ? infoEdit.seats : ""}
                      rules={{
                        required: {
                          value: watchIsVehicle?.vehicle === 1 ? true : false,
                          message:
                            "Por favor, informe a quantidade de assentos",
                        },
                        pattern: {
                          value: /^[0-9]*$/,
                          message: "Por favor, insira somente números",
                        },
                      }}
                      render={({ field }: any) => (
                        <Form.Control
                          {...field}
                          variant="standard"
                          margin="normal"
                          required
                          autoComplete='off'
                          onChange={(e) => {
                            const value = e.target.value;
                            if (/^[0-9]*$/.test(value)) {
                              field.onChange(e);
                            }
                          }}
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="seats"
                      render={({ message }) => (
                        <small style={{ color: "red" }}>{message}</small>
                      )}
                    />
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="4"
                    controlId=""
                    className={
                      "mb-3 " +
                      (watchIsVehicle?.ticket === 1 ? "d-block" : "d-none")
                    }
                  >
                    <Form.Label>Guia Informa Custo?:</Form.Label>
                    <Controller
                      control={control}
                      name="costInformation"
                      defaultValue={isEdit === true ? infoEdit.costInformation : ""}
                      rules={{
                        required: {
                          value: watchIsVehicle?.ticket === 1 ? true : false,
                          message:
                            "Por favor, informe se o recurso é pra ter um preco final informado pelo guia",
                        },
                      }}
                      render={({ field }: any) => (
                        <div className="endpoint">
                          <Form.Select
                            {...field}
                            variant="standard"
                            margin="normal"
                            required
                          >
                            <option value="">Selecione</option>
                            <option
                              value="1"
                              selected={
                                isEdit === true && infoEdit.costInformation === 1
                              }
                            >
                              Sim
                            </option>
                            <option
                              value="2"
                              selected={
                                isEdit === true && infoEdit.costInformation === 2
                              }
                            >
                              Não
                            </option>
                          </Form.Select>
                        </div>
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="costInformation"
                      render={({ message }) => (
                        <small style={{ color: "red" }}>{message}</small>
                      )}
                    />
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="4"
                    controlId=""
                    className={
                      "mb-3 " +
                      (watchIsVehicle?.vehicle === 1 ? "d-block" : "d-none")
                    }
                  >
                    <Form.Label>Informação do Veiculo:</Form.Label>
                    <Controller
                      control={control}
                      name="vehicleIdentification"
                      defaultValue={isEdit === true ? infoEdit.vehicleIdentification : ""}
                      rules={{
                        required: {
                          value: watchIsVehicle?.vehicle === 1 ? true : false,
                          message:
                            "Por favor, informe a identificação do veículo",
                        },
                      }}
                      render={({ field }: any) => (
                        <Form.Control
                          {...field}
                          variant="standard"
                          margin="normal"
                          required
                          autoComplete='off'
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="vehicleIdentification"
                      render={({ message }) => (
                        <small style={{ color: "red" }}>{message}</small>
                      )}
                    />
                  </Form.Group>

                  <Form.Group as={Col} md="4" controlId="" className="mb-3">
                    <Form.Label>Nome do Resource:</Form.Label>
                    <Controller
                      control={control}
                      name="resourceName"
                      defaultValue={
                        isEdit === true ? infoEdit.resourceName : ""
                      }
                      rules={{
                        required: {
                          value: true,
                          message: "Por favor, informe o nome do resource",
                        },
                      }}
                      render={({ field }: any) => (
                        <Form.Control
                          {...field}
                          variant="standard"
                          margin="normal"
                          required
                          autoComplete='off'
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="resourceName"
                      render={({ message }) => (
                        <small style={{ color: "red" }}>{message}</small>
                      )}
                    />
                  </Form.Group>

                  <Form.Group as={Col} md="4" controlId="" className="mb-3">
                    <Form.Label>Tipo de Pagamento:</Form.Label>
                    <Controller
                      control={control}
                      name="payment"
                      rules={{
                        required: {
                          value: true,
                          message: "Por favor, informe o tipo de pagamento",
                        },
                      }}
                      render={({ field }: any) => (
                        <div className="endpoint">
                          <Form.Select
                            {...field}
                            variant="standard"
                            margin="normal"
                            required
                          >
                            <option value="">Selecione</option>
                            <option
                              value="1"
                              selected={
                                isEdit === true && infoEdit.paymentType === 1
                              }
                            >
                              Por Pessoa
                            </option>
                            <option
                              value="2"
                              selected={
                                isEdit === true && infoEdit.paymentType === 2
                              }
                            >
                              Por Grupo
                            </option>
                          </Form.Select>
                        </div>
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="payment"
                      render={({ message }) => (
                        <small style={{ color: "red" }}>{message}</small>
                      )}
                    />
                  </Form.Group>
                </Row>
              </>
              {/*  */}
              <div
                className="d-flex justify-content-end"
                style={{ width: "unset" }}
              >
                <button
                  className="btn btn-default btn-primary px-4 text-uppercase"
                  type="submit"
                >
                  Cadastrar
                </button>
              </div>
              <Row className="mt-5">
                {/* <Form.Group as={Col} md="12" controlId="">
                  <ModalConfirm
                    confirm={responseText}
                    modalContent={modalResponse}
                    log={log}
                  />
                </Form.Group> */}
              </Row>
            </Container>
          </Card.Body>
        </Accordion>
      </Card>
    </>
  );
};

export default ResourceCard;
